import axios from "axios";
import { getToken, isValidToken, removeToken } from "./token";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

axiosInstance.interceptors.request.use((req) => {
  if (req.url === "/session" && !isValidToken()) {
    throw new Error("Invalid session token");
  }
  if (isValidToken()) req.headers.authorization = getToken();

  return req;
});

axiosInstance.interceptors.response.use(
  (res) => res?.data || {},
  (err) => {
    if (err.code === "ERR_NETWORK") return Promise.reject(err.message);

    if (
      (err.response.data.statusCode === 401 || err.response.status === 401) &&
      err.response.config.url !== "/session" // The private router check this
    ) {
      removeToken();
      window.location.href = "/";
    }
    return Promise.reject(err);
  }
);

export const loginAdmin = async (auth) => {
  const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/admin/login`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(auth),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  if (!res.ok) throw new Error("Error, check your credentials");
  const data = await res.json();

  return data;
};

export async function createBadge(data) {
  const res = await axiosInstance.post("/admin/badge", data);
  return res;
}

export async function getBadges(params) {
  const res = await axiosInstance.get("/admin/badge", {
    params: params?.query,
  });
  return res;
}

export async function editBadge({ id, data }) {
  const res = await axiosInstance.put(`/admin/badge/${id}`, data);
  return res;
}

export async function deleteBadge(id) {
  const res = await axiosInstance.delete(`/admin/badge/${id}`);
  return res;
}

export async function createObjetive(data) {
  const res = await axiosInstance.post("/admin/objetive", data);
  return res;
}

export async function getObjetives(params) {
  const res = await axiosInstance.get("/admin/objetive", {
    params: params?.query,
  });
  return res;
}
export async function getObjetiveWithProgress(params) {
  const res = await axiosInstance.get("/admin/objetiveWithProgress", {
    params: params?.query,
  });
  return res;
}

export async function editObjetive({ id, data }) {
  const res = await axiosInstance.put(`/admin/objetive/${id}`, data);
  return res;
}

export async function deleteObjetive(id) {
  const res = await axiosInstance.delete(`/admin/objetive/${id}`);
  return res;
}

export async function createBoost(data) {
  const res = await axiosInstance.post("/admin/boost", data);
  return res;
}

export async function getBoosts(params) {
  const res = await axiosInstance.get("/admin/boost", {
    params: params?.query,
  });
  return res;
}

export async function editBoost({ id, data }) {
  const res = await axiosInstance.put(`/admin/boost/${id}`, data);
  return res;
}

export async function deleteBoost(id) {
  const res = await axiosInstance.delete(`/admin/boost/${id}`);
  return res;
}

export async function createProvider(data) {
  const res = await axiosInstance.post("/admin/provider", data);
  return res;
}

export async function getProviders(params) {
  const res = await axiosInstance.get("/admin/provider", {
    params: params?.query,
  });
  return res;
}

export async function editProvider({ id, data }) {
  const res = await axiosInstance.put(`/admin/provider/${id}`, data);
  return res;
}

export async function deleteProvider(id) {
  const res = await axiosInstance.delete(`/admin/provider/${id}`);
  return res;
}

export async function getProviderContent(id) {
  const res = await axiosInstance.get(`/admin/provider/${id}/content`);
  return res;
}

export async function createTrainingSubCategory(data) {
  const res = await axiosInstance.post("/admin/trainingSubCategory", data);
  return res;
}

export async function getTrainingSubCategories(params) {
  const res = await axiosInstance.get("/admin/trainingSubCategory", {
    params: params?.query,
  });
  return res;
}

export async function editTrainingSubCategory({ id, data }) {
  const res = await axiosInstance.put(`/admin/trainingSubCategory/${id}`, data);
  return res;
}

export async function deleteTrainingSubCategory(id) {
  const res = await axiosInstance.delete(`/admin/trainingSubCategory/${id}`);
  return res;
}

export async function createTrainingTutorial(data) {
  const res = await axiosInstance.post("/admin/trainingTutorial", data);
  return res;
}

export async function getTrainingTutorials(params) {
  const res = await axiosInstance.get("/admin/trainingTutorial", {
    params: params?.query,
  });
  return res;
}

export async function editTrainingTutorial({ id, data }) {
  const res = await axiosInstance.put(`/admin/trainingTutorial/${id}`, data);
  return res;
}

export async function deleteTrainingTutorial(id) {
  const res = await axiosInstance.delete(`/admin/trainingTutorial/${id}`);
  return res;
}

export async function createOnboardingTutorial(data) {
  const res = await axiosInstance.post("/admin/onboardingTutorial", data);
  return res;
}

export async function getOnboardingTutorials() {
  const res = await axiosInstance.get("/admin/onboardingTutorial");
  return res;
}

export async function getOnboardingTutorialById(id) {
  const res = await axiosInstance.get(`/admin/onboardingTutorial/${id}`)
  return res;
}

export async function editOnboardingTutorial({ id, data }) {
  const res = await axiosInstance.put(`/admin/onboardingTutorial/${id}`, data);
  return res;
}

export async function deleteOnboardingTutorialById(id) {
  const res = await axiosInstance.delete(`/admin/onboardingTutorial/${id}`);
  return res;
}

export async function createTrainingExercise(data) {
  const res = await axiosInstance.post("/admin/trainingExercise", data);
  return res;
}

export async function getTrainingExercises(params) {
  const res = await axiosInstance.get("/admin/trainingExercise", {
    params: params?.query,
  });
  return res;
}

export async function editTrainingExercise({ id, data }) {
  const res = await axiosInstance.put(`/admin/trainingExercise/${id}`, data);
  return res;
}

export async function deleteTrainingExercise(id) {
  const res = await axiosInstance.delete(`/admin/trainingExercise/${id}`);
  return res;
}

export async function createTrainingCategory(data) {
  const res = await axiosInstance.post("/admin/trainingCategory", data);
  return res;
}

export async function getTrainingCategories(params) {
  const res = await axiosInstance.get("/admin/trainingCategory", {
    params: params?.query,
  });
  return res;
}

export async function editTrainingCategory({ id, data }) {
  const res = await axiosInstance.put(`/admin/trainingCategory/${id}`, data);
  return res;
}

export async function deleteTrainingCategory(id) {
  const res = await axiosInstance.delete(`/admin/trainingCategory/${id}`);
  return res;
}

export async function createLegalDoc(data) {
  const res = await axiosInstance.post("/admin/legalDoc", data);
  return res;
}

export async function getLegalDocs(params) {
  const res = await axiosInstance.get("/admin/legalDoc", { params: params?.query });
  return res;
}

export async function editLegalDocById({ id, data }) {
  const res = await axiosInstance.put(`/admin/legalDoc/${id}`, data);
  return res;
}

export async function deleteLegalDocById(id) {
  const res = await axiosInstance.delete(`/admin/legalDoc/${id}`);
  return res;
}

export async function createWordle(data) {
  const res = await axiosInstance.post("/admin/wordleGame", data);
  return res;
}

export async function getWordles(params) {
  const res = await axiosInstance.get("/admin/wordleGame", { params: params?.query });
  return res;
}

export async function editWordle({ id, data }) {
  const res = await axiosInstance.put(`/admin/wordleGame/${id}`, data);
  return res;
}

export async function deleteWordle(id) {
  const res = await axiosInstance.delete(`/admin/wordleGame/${id}`);
  return res;
}

export async function getUserFCMTokens(params) {
  const res = await axiosInstance.get("/admin/fcmTokens", { params: params?.query });
  return res;
}

export async function sendFCMNotification({ tokens, title, body }) {
  const res = axiosInstance.post("sendNotification", {
    tokens,
    data: {
      notification: {
        title,
        body,
      },
    },
  });
  return res;
}

export async function changeCategoryOrder(data) {
  const res = await axiosInstance.put("/admin/categorySubmission/order", data);
  return res;
}

export async function postCategorySubmission(data) {
  const res = await axiosInstance.post("/admin/categorySubmission", data);
  return res;
}

export async function getCategoriesSubmission(params) {
  const res = await axiosInstance.get("/admin/categorySubmission", {
    params: params?.query,
  });
  return res;
}

export async function putCategorySubmission({ id, data }) {
  const res = await axiosInstance.put(`/admin/categorySubmission/${id}`, data);
  return res;
}

export async function deleteCategorySubmission(id) {
  const res = await axiosInstance.delete(`/admin/categorySubmission/${id}`);
  return res;
}

export async function postBanner(data) {
  const res = await axiosInstance.post("/admin/banner", data);
  return res;
}

export async function getBanners(params) {
  const res = await axiosInstance.get("/admin/banner", { params: params?.query });
  return res;
}

export async function putBanner({ id, data }) {
  const res = await axiosInstance.put(`/admin/banner/${id}`, data);
  return res;
}

export async function deleteBanner(id) {
  const res = await axiosInstance.delete(`/admin/banner/${id}`);
  return res;
}

export async function checkValidationKey(data) {
  const res = await axiosInstance.get("/admin/validationKey/validate", {
    params: data,
  });
  return res;
}

export async function postValidationKey(data) {
  const res = await axiosInstance.post("/admin/validationKey", data);
  return res;
}

export async function getValidationKeys(params) {
  const res = await axiosInstance.get("/admin/validationKey", {
    params: params?.query,
  });
  return res;
}

export async function getValidationKey() {
  const res = await axiosInstance.get("/admin/validationKey");
  return res;
}

export async function getValidationKeyDashboard(validationKey) {
  const res = await axiosInstance.get(`/admin/validationKey/dashboard/${validationKey}`);
  return res;
}

export async function putValidationKey({ id, data }) {
  const res = await axiosInstance.put(`/admin/validationKey/${id}`, data);
  return res;
}

export async function deleteValidationKey(id) {
  const res = await axiosInstance.delete(`/admin/validationKey/${id}`);
  return res;
}

export async function setCronbJob(data) {
  const res = await axiosInstance.post("/admin/schedule", data);
  return res;
}

// prediction
export async function postPredictionCreate(params) {
  const response = await axiosInstance.post("/admin/predictions/create", params?.body);
  return response;
}

export async function postStory(data) {
  return await axiosInstance.post("story", data);
}

export async function updateStory({ storyId, data }) {
  return await axiosInstance.put(`story/${storyId}`, data);
}

export async function deleteStory(storyId) {
  return await axiosInstance.delete(`story/${storyId}`);
}

export async function getStories(query) {
  return await axiosInstance.get("story", {
    params: query,
  });
}

// daily rewards
export async function postDailyReward(params) {
  const response = await axiosInstance.post("/admin/consistencyReward", params?.body);
  return response;
}

export async function getDailyRewards(params) {
  const response = await axiosInstance.get("/admin/consistencyReward", {
    params: params?.query,
  });
  return response;
}

export async function putDailyReward(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/consistencyReward/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteDailyRewardId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/consistencyReward/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

// trivia
export async function postTriviaQuestion(params) {
  const response = await axiosInstance.post("/admin/triviaQuestion", params?.body);
  return response;
}

export async function getTriviaQuestions(params) {
  const response = await axiosInstance.get("/admin/triviaQuestion", {
    params: params?.query,
  });
  return response;
}

//batch
export async function getModelsNames() {
  const res = await axiosInstance.get("/admin/modelsNames");
  return res;
}

export async function postBatch({ path, batch }) {
  console.log(path, batch);
  const res = await Promise.all(batch.map((data) => axiosInstance.post(path, data)));
  return res;
}

export async function putTriviaQuestion(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/triviaQuestion/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteTriviaQuestionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/triviaQuestion/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

// aws
export async function postAwsSignUrl(params) {
  const response = await axiosInstance.post("/admin/aws/signUrl", params?.body);
  return response;
}

export async function getAdmin(params) {
  const response = await axiosInstance.get("/admin/admin", { params: params?.query });
  return response;
}

/**
 * return the admin created
 */
export async function postAdmin(params) {
  const response = await axiosInstance.post("/admin/admin", params?.body);
  return response;
}

export async function getCampaignsByCompany(companyId) {
  const response = await axiosInstance.get(`/admin/campaign?company_id=${companyId}`);
  return response;
}

export async function getCampaign(params) {
  const response = await axiosInstance.get("/admin/campaign", {
    params: params?.query,
  });
  return response;
}

export async function postCampaign(params) {
  const response = await axiosInstance.post("/admin/campaign", params?.body);
  return response;
}

export async function getCompany(params) {
  const response = await axiosInstance.get("/admin/company", {
    params: params?.query,
  });
  return response;
}

export async function postCompany(params) {
  const response = await axiosInstance.post("/admin/company", params?.body);
  return response;
}

export async function getEpisode(params) {
  const response = await axiosInstance.get("/admin/episode", {
    params: {
      campaign_id: params?.query.campaign_id,
    },
  });
  return response;
}

export async function postEpisode(params) {
  const response = await axiosInstance.post("/admin/episode", params?.body, {
    params: params?.query,
  });
  return response;
}

export async function getEpisodeMission(params) {
  const response = await axiosInstance.get("/admin/episodeMission", {
    params: {
      ...params?.query,
    },
  });
  return response;
}

export async function postEpisodeMission(params) {
  const response = await axiosInstance.post("/admin/episodeMission", params?.body);
  return response;
}

export async function getEpisodeMissionSubmission(params) {
  const response = await axiosInstance.get("/admin/episodeMissionSubmission", {
    params: params?.query,
  });
  return response;
}

export async function postEpisodeMissionSubmission(params) {
  const response = await axiosInstance.post("/admin/episodeMissionSubmission", params?.body);
  return response;
}
export async function getDashboard(params) {
  const response = await axiosInstance.get("/admin/dashboard", {
    params: params?.query,
  });
  return response;
}

export async function getLevel(params) {
  const response = await axiosInstance.get("/admin/level", { params: params?.query });
  return response;
}

export async function postLevel(params) {
  const response = await axiosInstance.post("/admin/level", params?.body);
  return response;
}

export async function getReward(params) {
  const response = await axiosInstance.get("/admin/reward", { params: params?.query });
  return response;
}

export async function postReward(params) {
  const response = await axiosInstance.post("/admin/reward", params?.body);
  return response;
}

export async function getRewardCollection(params) {
  const response = await axiosInstance.get("/admin/rewardCollection", {
    params: params?.query,
  });
  return response;
}

export async function postRewardCollection(params) {
  const response = await axiosInstance.post("/admin/rewardCollection", params?.body);
  return response;
}

export async function getTemporalMission(params) {
  const response = await axiosInstance.get("/admin/temporalMission", {
    params: params?.query,
  });
  return response;
}

export async function getTemporalMissionWithDates(params) {
  const response = await axiosInstance.get("/admin/temporalMissionWithDates", {
    params: params,
  });
  return response;
}

export async function postTemporalMission(params) {
  const response = await axiosInstance.post("/admin/temporalMission", params?.body);
  return response;
}

export async function getTemporalMissionSubmission(params) {
  const response = await axiosInstance.get("/admin/temporalMissionSubmission", {
    params: params?.query || params,
  });
  return response;
}

export async function postTemporalMissionSubmission(params) {
  const response = await axiosInstance.post("/admin/temporalMissionSubmission", params?.body);
  return response;
}

export async function getAdminId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/admin/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putAdminId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/admin/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteAdminId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/admin/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getCampaignId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/campaign/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putCampaignId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/campaign/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteCampaignId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/campaign/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getCompanyId(companyId) {
  const response = await axiosInstance.get(`/admin/company/${companyId}`);
  return response;
}

export async function putCompanyId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }

  const response = await axiosInstance.put(`/admin/company/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteCompanyId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }

  const response = await axiosInstance.delete(`/admin/company/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getEpisodeId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/episode/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putEpisodeId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/episode/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteEpisodeId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/episode/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getEpisodeMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/episodeMission/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putEpisodeMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/episodeMission/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteEpisodeMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/episodeMission/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function postPost(postBody) {
  const response = await axiosInstance.post("/admin/post", postBody);
  return response;
}

export async function getPost({ campaign_id, author }) {
  const response = await axiosInstance.get(`/admin/post`, {
    params: { campaign_id, author },
  });
  return response;
}

export async function putPostId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/post/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deletePostId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/post/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function postRss(rssBody) {
  return await axiosInstance.post("rss", rssBody);
}

export async function getRss(params) {
  return await axiosInstance.get(`rss`, {
    params: params?.query,
  });
}

export async function putRssId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/rss/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteRssId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/rss/${params.path.id}`, { params: params?.query });
  return response;
}

export async function getReports(params) {
  const response = await axiosInstance.get(`/admin/report`, {
    params: params?.query,
  });
  return response;
}

export async function putReportId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/report/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function getEpisodeMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/episodeMissionSubmission/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putEpisodeMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/episodeMissionSubmission/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteEpisodeMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/episodeMissionSubmission/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getLevelId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/level/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putLevelId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/level/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteLevelId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/level/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getRewardId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/reward/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putRewardId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/reward/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteRewardId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/reward/${params.path.id}`, {
    params: { ...params?.query, level_id: params.path.levelId },
  });
  return response;
}

export async function getRewardCollectionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/rewardCollection/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putRewardCollectionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/rewardCollection/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteRewardCollectionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/reward/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}
export async function getTemporalMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/temporalMission/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putTemporalMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/temporalMission/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteTemporalMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/temporalMission/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getTemporalMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/temporalMissionSubmission/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putTemporalMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/temporalMissionSubmission/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteTemporalMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/temporalMissionSubmission/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export const createApikey = async () => {
  console.log("post");
  const response = await axiosInstance.post("/admin/api-key");
  return response;
};

export const getApikeys = async () => {
  const response = await axiosInstance.get("/admin/api-key");
  return response;
};

export const deleteApikey = async (id) => {
  const response = await axiosInstance.delete(`/admin/api-key/${id}`);
  return response;
};

export const createDisccountCode = async (data) => {
  const res = await axiosInstance.post("/admin/disccountCode", data);
  return res;
};

export const getDisccountCodes = async (params) => {
  const res = await axiosInstance.get("/admin/disccountCode", {
    params: params?.query,
  });
  return res;
};

export const getDisccountCodeById = async (id) => {
  const res = await axiosInstance.get(`/admin/disccountCode/${id}`);
  return res;
};

export const putDisccountCodeById = async (id, data) => {
  const res = await axiosInstance.put(`/admin/disccountCode/${id}`, data);
  return res;
};

export const deleteDisccountCodeById = async (id) => {
  const res = await axiosInstance.delete(`/admin/disccountCode/${id}`);
  return res;
};

export const getUserInfo = async () => {
  const response = await axiosInstance.get("/admin/session");
  return response;
};

export async function getPollQuestions(params) {
  const response = await axiosInstance.get("/admin/pollQuestion", {
    params: {
      campaign_id: params?.query.campaign_id,
    },
  });
  return response;
}

export async function postPollQuestion(params) {
  const response = await axiosInstance.post("/admin/pollQuestion", params?.body, {
    params: params?.query,
  });
  return response;
}

export async function getPollQuestionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`/admin/pollQuestion/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putPollQuestionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/pollQuestion/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deletePollQuestionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`/admin/pollQuestion/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getFeedback() {
  const res = await axiosInstance.get("/admin/feedback");
  return res;
}

export async function putFeedback(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(`/admin/feedback/${params.path.id}`, params?.body, {
    params: params?.query,
  });
  return response;
}

// user info
export async function getUsers(params) {
  const response = await axiosInstance.get("/admin/user", {
    params: params?.query,
  });
  return response;
}

export async function getPlayers(params) {
  const response = await axiosInstance.get("/admin/player", {
    params: params?.query,
  });
  return response;
}

// user page data
export async function getUserPageData(params) {
  const response = await axiosInstance.get("/admin/userData", {
    params: params?.query,
  });
  return response;
}

export async function postUpdateUserPoints(params) {
  const response = await axiosInstance.post("/admin/user/updatePoints", params?.body);
  return response;
}
export async function postRemoveUserPoints(params) {
  const response = await axiosInstance.post("/admin/user/removePoints", params?.body);
  return response;
}
export async function postResetTriviaLives(params) {
  const response = await axiosInstance.post("/admin/user/resetTriviaLives", params?.body);
  return response;
}
export async function AddOneTriviaLife(params) {
  const response = await axiosInstance.post("/admin/user/AddOneTriviaLife", params?.body);
  return response;
}
export async function postGiveRewardToUser(params) {
  const response = await axiosInstance.post("/admin/user/giveReward", params?.body);
  return response;
}

export async function postToggleUserBlocked(body) {
  return await axiosInstance.post("/user/toggleBlocked", body);
}

export async function postRemoveUserReward(params) {
  const response = await axiosInstance.post("/admin/user/removeReward", params?.body);
  return response;
}

export async function getGames(query) {
  return await axiosInstance.get("game", {
    params: query,
  });
}

export async function getScheduleStatus() {
  return await axiosInstance.get("/games/schedule/status");
}

export async function postScheduleUpdate(payload) {
  return await axiosInstance.post("/games/schedule/update", payload);
}

export async function deleteGame(gameId) {
  return await axiosInstance.delete(`game/${gameId}`);
}



//USER ROUTES

export async function getUserObjectives(query) {
  return axiosInstance.get(`/user/getObjetives`, {params:query});
}
export async function reviewUserObjective(query) {
  return axiosInstance.get(`/user/reviewObjective`, {params:query});
}